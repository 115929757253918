$(document).ready(function () {
    $.fn.dataTable.moment('DD-MM-YYYY HH:mm:ss');
    var table = $('.dataTable').DataTable({
        "language": {
            "sEmptyTable": "No data available in table",
            "sInfo": "Showing _START_ to _END_ of _TOTAL_ transactions",
            "sInfoEmpty": "Showing 0 to 0 of 0 transactions",
            "sInfoFiltered": "(filtered from _MAX_ total transactions)",
            "sInfoPostFix": "",
            "sInfoThousands": ",",
            "sLengthMenu": "Show _MENU_ transactions",
            "sLoadingRecords": "<div class=\"loader\">Loading...</div>",
            "sProcessing": "<div class=\"loader\">Loading...</div>",
            "sSearch": "Search:",
            "sZeroRecords": "No matching transactions found",
            "oPaginate": {
                "sFirst": "First",
                "sLast": "Last",
                "sNext": "Next",
                "sPrevious": "Previous"
            },
            "oAria": {
                "sSortAscending": ": activate to sort column ascending",
                "sSortDescending": ": activate to sort column descending"
            },

        },
        searchDelay: 1000,
        responsive: true,
        processing: true,
        serverSide: true,
        searching: true,
        "ajax": {
            "url": "transactions/filter",
            "type": "get",
            data: function (d) {
                d.from = $("#dateTime4").val(),
                    d.to = $("#dateTime5").val(),
                    d.status = $("#status").val(),
                    d.test = $("#test").val(),
                    d.payment_method = $("#payment_method").val(),
                    d.charges_lenght = $("#select[name=charges_length]").val(),
                    d.searchString = $("#input[type=search]").val()
                d.usedFor = 'normal'
            },
            dataSrc: function (json) {
                if (!isNaN(json.totalAmount)) {
                    $('#totals_text').removeClass('hidden');
                    $('#total_amount').text("€" + parseFloat(json.totalAmount).toFixed(2));
                } else {
                    $('#totals_text').addClass('hidden');
                }
                return json.data;
            },
            error: function (xhr, error, thrown) {
                alert('An error occured. Please try again later or contact us when this message still occurs.');
            }
        },
        dom: 'Blfrtip',
        buttons: [
            {
                text: 'PDF',
                action: function (d) {
                    var matches = $("#charges_info").text().replace(/,/g, "").match(/(\d+)/g);
                    if (parseInt(matches[2], 10) == 0) {
                        alert('No search results found for the output');
                    } else if (parseInt(matches[2], 10) <= parseInt(800, 10)) {
                        var getVars = {};
                        getVars['from'] = $("#dateTime4").val(),
                        getVars['to'] = $("#dateTime5").val(),
                        getVars['status'] = $("#status").val(),
                        getVars['test'] = $("#test").val(),
                        getVars['payment_method'] = $("#payment_method").val(),
                        getVars['usedFor'] = 'pdf'
                        getVars['charges_lenght'] = $("#select[name=charges_length]").val();
                        var search = {};
                        search['value'] = $("input[type=search]").val();
                        getVars['search'] = search;
                        window.open('transactions/filter?' + jQuery.param(getVars));
                    } else {
                        alert('The maximum amount for a PDF-file is 800 rows. If you want to export more transactions, please use the "Excel"-option');
                    }
                }
            },
            {
                text: 'Excel',
                action: function (d) {
                    var matches = $("#charges_info").text().replace(/,/g, "").match(/(\d+)/g);
                    if (parseInt(matches[2], 10) == 0) {
                        alert('No search results found for the output');
                    } else {
                        var getVars = {};
                        getVars['from'] = $("#dateTime4").val(),
                        getVars['to'] = $("#dateTime5").val(),
                        getVars['status'] = $("#status").val(),
                        getVars['test'] = $("#test").val(),
                        getVars['payment_method'] = $("#payment_method").val(),
                        getVars['usedFor'] = 'excel'
                        getVars['charges_lenght'] = $("#select[name=charges_length]").val();
                        var search = {};
                        search['value'] = $("input[type=search]").val();
                        getVars['search'] = search;
                        window.open('transactions/filter?' + jQuery.param(getVars));
                    }
                }
            }
        ],
        "order": [[0, "desc"]],
        "aoColumnDefs": [
            {
                "targets": 4,
                "orderable": true,
                "searchable": false,

            },
            {
                "aTargets": 6,
                "fnCreatedCell": function (nTd, sData, oData, iRow, iCol) {
                    $(nTd).html('<div class="status ' + sData.toLowerCase() + '">' + splitRefundingStatus(sData) + '</div>');
                }
            },
            {
                "aTargets": 7,
                "fnCreatedCell": function (nTd, sData, oData, iRow, iCol) {
                    $(nTd).html('<div class="status ' + ((sData == 'LIVE') ? 'success' : 'test') + '">' + sData + '</div>');
                }
            },
            {
                "targets": 8,
                "orderable": false,
                "searchable": false,
                "fnCreatedCell": function (nTd, sData, oData, iRow, iCol) {
                    $(nTd).html('<a href="javascript:;" class="showCharge" data-value="' + sData + '">Details</a>');
                }
            },
            {
                "targets": 9,
                "orderable": false,
                "searchable": false,
                "visible": false
            },
            {
                "targets": 10,
                "orderable": false,
                "searchable": false,
                "visible": false
            },
            {
                "aTargets": 11,
                "orderable": false,
                "fnCreatedCell": function (nTd, sData, oData, iRow, iCol) {
                    var htmlData = '';
                    if (sData.indexOf('finalize') != -1) {
                        var id = sData.substring(8);
                        htmlData = '<div>' + '<a href="javascript:;" class="doFinalize btn btn-info btn-sm finalize" data-value="' + id + '">' + 'Finalize' + '</a></div>';
                    } else if (sData !== '') {
                        htmlData = '<div>' + '<a href="javascript:;" id="refundButton' + sData + '" class="doRefund btn btn-info btn-sm refund" + data-value="' + sData + '">' + 'Refund' + '</a></div>';
                    }
                    $(nTd).html(htmlData);
                }
            },
            {
                type: 'currency',
                targets: 4
            }
        ],
        "lengthMenu": [10, 25, 50, 100, 200],
    });

    table.buttons().container()
        .appendTo('#charges_wrapper .col-sm-6:eq(0)');

    $('#payment_method').change(function () {
        table.draw();
    });

    $('#status').change(function () {
        table.draw();
    });

    $('#test').change(function () {
        table.draw();
    });

    $("#datetimepicker4").on("dp.change", function (e) {
        $('#datetimepicker5').data("DateTimePicker").minDate(e.date);
        setToDate();
        setFromDate();
        table.draw();

    });

    $("#datetimepicker5").on("dp.change", function (e) {
        $('#datetimepicker4').data("DateTimePicker").maxDate(e.date);

        setToDate();
        table.draw();

    });

    function setFromDate() {
        window.fromDate = moment($("#dateTime4").val(), 'DD-MM-YYYY').locale('nl');
    }

    function setToDate() {
        if ($("#dateTime5").val()) {
            window.toDate = moment($("#dateTime5").val(), 'DD-MM-YYYY').locale('nl');
        }

    }

    var alertContainer = $('#alert-container');

    if (alertContainer.length) {
        alerts.init();
        alerts.show(alertContainer.data('level'), alertContainer.data('message'), alertContainer.data('level'), 3000);
    }


});

$(document).on('click', '.showCharge', function () {
    var paymentID = $(this).data("value");
    retrievePaymentInformation(paymentID);
});

function splitRefundingStatus(status) {
    if (status.indexOf('Refund') != -1 && status != 'Refunded') {
        var newrefundtext = status.slice(0, 6) + ' ' + status.slice(6);
        return newrefundtext;
    } else {
        return status;
    }
}

function retrievePaymentInformation(paymentID) {
    $.ajax({
        url: "transactions/modaldetail/" + paymentID,
        dataType: 'json',
        success: function (data) {
            resetDivs();
            if (data.payment_method == 'iDEAL' || data.payment_method == 'iDEAL QR') {
                showModal(data);
                showIDEAL(data);
            } else if (data.payment_method == 'Bancontact') {
                showModal(data);
                showBancontact(data);
            } else if (data.payment_method == 'Creditcard') {
                showModal(data);
                showCreditcard(data);
            } else if (data.payment_method == 'AfterPay') {
                showModal(data);
                showAfterpay(data);
            } else if (data.payment_method == 'PayPal') {
                showModal(data);
                showPayPal(data);
            } else if (data.payment_method == 'SOFORT') {
                showModal(data);
                showSofort(data);
            } else if (data.payment_method == 'WireTransfer') {
                showModal(data);
                showWireTransfer(data);
            } else if (data.payment_method == 'DirectDebit') {
                showModal(data);
                showDirectDebit(data);
            } else if (data.payment_method == 'Alipay') {
                showModal(data);
                showAliPay(data);
            } else if (data.payment_method == 'WeChatPay') {
                showModal(data);
                showWeChatPay(data);
            } else {
                console.log("Unknown payment method " + data.payment_method);
            }
        },
        error: function (data) {
            alert('Something went wrong, please try again later')
        }
    });
}

$(document).on('click', '.doRefund', function () {
    var paymentID = $(this).data("value");
    requestRefund(paymentID);
});

$(document).on('click', '.doFinalize', function () {
    var paymentID = $(this).data("value");
    requestFinalize(paymentID);
});


function requestFinalize(paymentID) {
    $.ajax({
        url: "transactions/finalize/" + paymentID,
        dataType: 'json',
        success: function (data, status, jqhxr) {
            $('.dataTable').DataTable().ajax.reload();
            showModalRefund(data);
            showRefund(data);
        },
        error: function (data) {
            alert('Something went wrong, please try again later')
        }
    });

}

function requestRefund(paymentID) {
    $('#refundConfirmModal').unbind('click');
    $.ajax({
        url: "transactions/modaldetail/" + paymentID,
        dataType: 'json',
        success: function (data) {
            showInitialRefund(data);
        },
        error: function (data) {
            alert('Something went wrong while retrieving payment, please try again later')
        }
    });
    $('#refundConfirmModal').modal('toggle').on('click', '#goCreateRefund', function (e) {
        $('#refundButton' + paymentID).addClass('hidden');
        $.ajax({
            url: "transactions/refund/" + paymentID,
            dataType: 'json',
            success: function (data, status, jqhxr) {
                $('.dataTable').DataTable().ajax.reload();
                showModalRefund(data);
                showRefund(data);
            },
            error: function (data) {
                alert('Something went wrong creating a refund, please try again later')
            }
        });
    });
}

function resetDivs() {
    $('#accountInformationHeader').addClass('hidden');
    $('#iDEALPayDetails').addClass('hidden');
    $('.paymentDetailsQRForeignID').addClass('hidden');
    $('#idealPaymentPaidByIban').addClass('hidden');
    $('#BancontactPayDetails').addClass('hidden');
    $('#CreditcardPayDetails').addClass('hidden');
    $('#allAfterPay').addClass('hidden');
    $('#PayPalPayDetails').addClass('hidden');
    $('#PayPalUserInformation').addClass('hidden');
    $('#SOFORTPayDetails').addClass('hidden');
    $('#SOFORTUserInformation').addClass('hidden');
    $('#WireTransferPayDetails').addClass('hidden');
    $('#DirectDebitPayDetails').addClass('hidden');
    $('#AliPayDetails').addClass('hidden');

    /* Make all the datafields empty */

    $('.value-field').empty();
}


function showModalRefund(data) {
    $('#refundModal').modal('toggle');
    $('#refundId').text(data.refund_id);
}

function showRefund(data) {

    //Reset all the fields
    $('#refundTable .created td:nth-child(2)').empty();
    $('#refundTable .status td:nth-child(2)').empty();
    $('#refundTable .currency td:nth-child(2)').empty();
    $('#refundTable .amount td:nth-child(2)').empty();
    if (typeof data.refund_details.refund_iban !== 'undefined') {
        $('#refundTable .iban').removeClass('hidden');
        $('#refundTable .iban td:nth-child(2)').empty();
        $('#refundTable .bic').removeClass('hidden');
        $('#refundTable .bic td:nth-child(2)').empty();
        $('#refundTable .name').removeClass('hidden');
        $('#refundTable .name td:nth-child(2)').empty();
        $('#refundTable .credittxref').removeClass('hidden');
        $('#refundTable .credittxref td:nth-child(2)').empty();
        $('#refundTable .credittxstatus').removeClass('hidden');
        $('#refundTable .credittxstatus td:nth-child(2)').empty();
        $('#refundTable .debittxref').removeClass('hidden');
        $('#refundTable .debittxref td:nth-child(2)').empty();
        $('#refundTable .debittxstatus').removeClass('hidden');
        $('#refundTable .debittxstatus td:nth-child(2)').empty();
    }
    $('#refundTable .test td:nth-child(2)').empty();

    //set all the fields

    $('#refundTable .created td:nth-child(2)').html(data.created_at);
    $('#refundTable .status td:nth-child(2)').html(data.status);
    $('#refundTable .currency td:nth-child(2)').html(data.currency);
    $('#refundTable .amount td:nth-child(2)').html(data.amount);
    $('#refundTable .iban td:nth-child(2)').html(data.refund_details.refund_iban);
    $('#refundTable .bic td:nth-child(2)').html(data.refund_details.refund_bic);
    $('#refundTable .name td:nth-child(2)').html(data.refund_details.refund_name);
    $('#refundTable .credittxref td:nth-child(2)').html(data.refund_details.credit_transaction_reference);
    $('#refundTable .credittxstatus td:nth-child(2)').html(data.refund_details.credit_transaction_status);
    $('#refundTable .debittxref td:nth-child(2)').html(data.refund_details.debit_transaction_reference);
    $('#refundTable .debittxstatus td:nth-child(2)').html(data.refund_details.debit_transaction_status);
    $('#refundTable .test td:nth-child(2)').html(((data.test == false) ? 'Live' : 'Test'));
}

function showInitialRefund(data) {
    //Reset all the fields
    $('#initialRefundTable .currency td:nth-child(2)').empty();
    $('#initialRefundTable .amount td:nth-child(2)').empty();
    if (data.payment_method.toUpperCase().localeCompare('IDEAL') == 0) {
        $('#initialRefundTable .iban').removeClass('hidden');
        $('#initialRefundTable .iban td:nth-child(2)').empty();
        $('#initialRefundTable .bic').removeClass('hidden');
        $('#initialRefundTable .bic td:nth-child(2)').empty();
        $('#initialRefundTable .name').removeClass('hidden');
        $('#initialRefundTable .name td:nth-child(2)').empty();
    } else {
        $('#initialRefundTable .iban').addClass('hidden');
        $('#initialRefundTable .iban td:nth-child(2)').empty();
        $('#initialRefundTable .bic').addClass('hidden');
        $('#initialRefundTable .bic td:nth-child(2)').empty();
        $('#initialRefundTable .name').addClass('hidden');
        $('#initialRefundTable .name td:nth-child(2)').empty();
    }

    //set all the fields

    $('#initialRefundTable .currency td:nth-child(2)').html(data.currency);
    $('#initialRefundTable .amount td:nth-child(2)').html(data.amount);
    $('#initialRefundTable .iban td:nth-child(2)').html(data.payment_details.paid_by_ascript);
    $('#initialRefundTable .bic td:nth-child(2)').html(data.payment_details.paid_by_bic);
    $('#initialRefundTable .name td:nth-child(2)').html(data.payment_details.paid_by_iban);
}


function showModal(data) {
    $('#chargeModal').modal('toggle');
    $('#chargeId').text(data.payment_id);
}


function showSofort(data) {
    $('#SOFORTPayDetails').removeClass('hidden');

    $('#SOFORTTable .created td:nth-child(2)').text(data.created_at);
    $('#SOFORTTable .paymentDetailsTransactionId td:nth-child(2)').text(data.payment_details.transaction_id);
    $('#SOFORTTable .paymentDetailsPurchase_id td:nth-child(2)').text(data.payment_details.purchase_id);
    $('#SOFORTTable .currency td:nth-child(2)').text(data.currency);
    $('#SOFORTTable .amount td:nth-child(2)').text(data.amount);
    $('#SOFORTTable .paymentDetailsDescription td:nth-child(2)').text(data.payment_details.description);


    $('#SOFORTTable .status td:nth-child(2) div').text(data.status);
    $('#SOFORTTable .test td:nth-child(2) div').text(((data.test == false) ? 'Live' : 'Test'));
    $('#SOFORTTable .status td:nth-child(2) div').removeClass();
    $('#SOFORTTable .test td:nth-child(2) div').removeClass();
    $('#SOFORTTable .status td:nth-child(2) div').addClass('value-field status ' + data.status.toLowerCase());
    $('#SOFORTTable .test td:nth-child(2) div').addClass('value-field status ' + ((data.test == false) ? 'success' : 'test'));

    if (data.status == 'Accepted') {
        $('#accountInformationHeader').removeClass('hidden');
        $('#SOFORTUserInformation').removeClass('hidden');
        $('#SOFORTUserInformation .paymentDetailsConsumerName td:nth-child(2)').text(data.payment_details.consumer_name);
        $('#SOFORTUserInformation .paymentDetailsPaidByBIC td:nth-child(2)').text(data.payment_details.bank_bic);
        $('#SOFORTUserInformation .paymentDetailsPaidByIban td:nth-child(2)').text(data.payment_details.bank_account_number);
    }
}


function showPayPal(data) {
    $('#PayPalPayDetails').removeClass('hidden');

    $('#PayPalTable .created td:nth-child(2)').text(data.created_at);
    $('#PayPalTable .paymentDetailsTransactionId td:nth-child(2)').text(data.payment_details.transaction_id);
    $('#PayPalTable .paymentDetailsPurchase_id td:nth-child(2)').text(data.payment_details.purchase_id);
    $('#PayPalTable .currency td:nth-child(2)').text(data.currency);
    $('#PayPalTable .amount td:nth-child(2)').text(data.amount);

    $('#PayPalTable .fee_currency td:nth-child(2)').text(data.payment_details.fee_currency);
    $('#PayPalTable .fee_amount td:nth-child(2)').text(data.payment_details.fee_amount);
    $('#PayPalTable .paymentDetailsDescription td:nth-child(2)').text(data.payment_details.description);

    $('#PayPalTable .status td:nth-child(2) div').text(data.status);
    $('#PayPalTable .test td:nth-child(2) div').text(((data.test == false) ? 'Live' : 'Test'));
    $('#PayPalTable .status td:nth-child(2) div').removeClass();
    $('#PayPalTable .test td:nth-child(2) div').removeClass();
    $('#PayPalTable .status td:nth-child(2) div').addClass('value-field status ' + data.status.toLowerCase());
    $('#PayPalTable .test td:nth-child(2) div').addClass('value-field status ' + ((data.test == false) ? 'success' : 'test'));

    if (data.status == 'Success') { /* for now, we hide this stuff */
        $('#accountInformationHeader').removeClass('hidden');
        $('#PayPalUserInformation').removeClass('hidden');
        $('#PayPalUserInformation .paymentDetailsFirstname td:nth-child(2)').text(data.payment_details.payer_first_name);
        $('#PayPalUserInformation .paymentDetailsLastname td:nth-child(2)').text(data.payment_details.payer_last_name);
        $('#PayPalUserInformation .paymentDetailsCity td:nth-child(2)').text(data.payment_details.payer_city);
        $('#PayPalUserInformation .paymentDetailsEmail td:nth-child(2)').text(data.payment_details.payer_email);
    }
}

function showWireTransfer(data) {
    $('#WireTransferPayDetails').removeClass('hidden');
    $('#wireTransferTable').removeClass('hidden');
    $('#wireTransferPaymentPaidByIban').removeClass('hidden');

    $('#wireTransferTable .status td:nth-child(2) span').removeClass();
    $('#wireTransferTable .test td:nth-child(2) span').removeClass();
    $('#wireTransferPaymentPaidByIban .paymentDetailsPaidByAscript td:nth-child(2)').removeClass();
    $('#wireTransferPaymentPaidByIban .paymentDetailsPaidByIban td:nth-child(2)').removeClass();
    $('#wireTransferPaymentPaidByIban .paymentDetailsPaidByBIC td:nth-child(2)').removeClass();
    $('#wireTransferPaymentPaidByIban').addClass('hidden');

    //set all the fields
    $('#wireTransferTable .created td:nth-child(2)').text(data.created_at);
    $('#wireTransferTable .expirationDate td:nth-child(2)').text(data.expires_at);
    $('#wireTransferTable .paymentShortPaymentId td:nth-child(2)').text(data.short_payment_id);
    $('#wireTransferTable .paymentDetailsPurchase_id td:nth-child(2)').text(data.payment_details.purchase_id);
    $('#wireTransferTable .currency td:nth-child(2)').text(data.currency);
    $('#wireTransferTable .amount td:nth-child(2)').text(data.amount);
    $('#wireTransferTable .status td:nth-child(2) span').text(data.status);
    $('#wireTransferTable .test td:nth-child(2) span').text(((data.test == false) ? 'Live' : 'Test'));
    $('#wireTransferTable .status td:nth-child(2) span').addClass('value-field status ' + data.status.toLowerCase());
    $('#wireTransferTable .test td:nth-child(2) span').addClass('value-field status ' + ((data.test == false) ? 'success' : 'test'));

    if (data.payment_details.debit_iban) {
        $('#accountInformationHeader').removeClass('hidden');
        $('#wireTransferPaymentPaidByIban').removeClass('hidden');
        $('#wireTransferPaymentPaidByIban .paymentDetailsPaidByAscript td:nth-child(2)').text(data.payment_details.debit_name);
        $('#wireTransferPaymentPaidByIban .paymentDetailsPaidByIban td:nth-child(2)').text(data.payment_details.debit_iban);
        $('#wireTransferPaymentPaidByIban .paymentDetailsConsumerDescription td:nth-child(2)').text(data.payment_details.consumer_description);
    }
}

function showWeChatPay(data) {
    $('#WeChatPayDetails').removeClass('hidden');
    $('#wechatpayTable').removeClass('hidden');

    $('#wechatpayTable .status td:nth-child(2) span').removeClass();
    $('#wechatpayTable .test td:nth-child(2) span').removeClass();

    //set all the fields
    $('#wechatpayTable .created td:nth-child(2)').text(data.created_at);
    $('#wechatpayTable .expirationDate td:nth-child(2)').text(data.expires_at);
    $('#wechatpayTable .paymentShortPaymentId td:nth-child(2)').text(data.short_payment_id);
    $('#wechatpayTable .paymentDetailsPurchase_id td:nth-child(2)').text(data.payment_details.purchase_id);
    $('#wechatpayTable .currency td:nth-child(2)').text(data.currency);
    $('#wechatpayTable .amount td:nth-child(2)').text(data.amount);
    $('#wechatpayTable .status td:nth-child(2) span').text(data.status);
    $('#wechatpayTable .test td:nth-child(2) span').text(((data.test == false) ? 'Live' : 'Test'));
    $('#wechatpayTable .status td:nth-child(2) span').addClass('value-field status ' + data.status.toLowerCase());
    $('#wechatpayTable .test td:nth-child(2) span').addClass('value-field status ' + ((data.test == false) ? 'success' : 'test'));
}


function showIDEAL(data) {
    $('#iDEALPayDetails').removeClass('hidden');
    $('#idealPaymentPaidByIban').removeClass('hidden');

    $('#idealTable .status td:nth-child(2) span').removeClass();
    $('#idealTable .test td:nth-child(2) span').removeClass();
    $('#idealPaymentPaidByIban .paymentDetailsPaidByAscript td:nth-child(2)').removeClass();
    $('#idealPaymentPaidByIban .paymentDetailsPaidByIban td:nth-child(2)').removeClass();
    $('#idealPaymentPaidByIban .paymentDetailsPaidByBIC td:nth-child(2)').removeClass();
    $('#idealPaymentPaidByIban').addClass('hidden');


    //set all the fields
    $('#idealTable .created td:nth-child(2)').text(data.created_at);
    $('#idealTable .paymentDetailsTransactionId td:nth-child(2)').text(data.payment_details.transaction_id);
    $('#idealTable .paymentDetailsPurchase_id td:nth-child(2)').text(data.payment_details.purchase_id);
    $('#idealTable .currency td:nth-child(2)').text(data.currency);
    $('#idealTable .amount td:nth-child(2)').text(data.amount);
    $('#idealTable .paymentDetailsIssuerId td:nth-child(2)').text(data.payment_details.issuer_id);

    $('#idealTable .paymentMethod td:nth-child(2)').text(data.payment_method);
    $('#idealTable .paymentDetailsDescription td:nth-child(2)').text(data.payment_details.description);


    $('#idealTable .status td:nth-child(2) div').text(data.status);
    $('#idealTable .test td:nth-child(2) div').text(((data.test == false) ? 'Live' : 'Test'));
    $('#idealTable .status td:nth-child(2) div').removeClass();
    $('#idealTable .status td:nth-child(2) div').addClass('value-field status ' + data.status.toLowerCase());
    $('#idealTable .test td:nth-child(2) div').removeClass();
    $('#idealTable .test td:nth-child(2) div').addClass('value-field status ' + ((data.test == false) ? 'success' : 'test'));

    if (data.payment_details.qr_foreign_id) {
        $('.paymentDetailsQRForeignID').removeClass('hidden');
        $('#idealTable .paymentDetailsQRForeignID td:nth-child(2)').text(data.payment_details.qr_foreign_id);
        $('#idealTable .paymentMethod td:nth-child(2)').text('iDEAL QR');
    }


    if (data.payment_details.paid_by_iban) {
        $('#accountInformationHeader').removeClass('hidden');
        $('#idealPaymentPaidByIban').removeClass('hidden');
        $('#idealPaymentPaidByIban .paymentDetailsPaidByAscript td:nth-child(2)').text(data.payment_details.paid_by_ascript);
        $('#idealPaymentPaidByIban .paymentDetailsPaidByIban td:nth-child(2)').text(data.payment_details.paid_by_iban);
        $('#idealPaymentPaidByIban .paymentDetailsPaidByBIC td:nth-child(2)').text(data.payment_details.paid_by_bic);
    }
}

function showDirectDebit(data) {
    $('#DirectDebitPayDetails').removeClass('hidden');

    $('#directDebitTable .status td:nth-child(2) span').removeClass();
    $('#directDebitTable .test td:nth-child(2) span').removeClass();
    $('#directDebitPaymentDetailTable .paymentDetailsPaidByAscript td:nth-child(2)').removeClass();
    $('#directDebitPaymentDetailTable .paymentDetailsPaidByIban td:nth-child(2)').removeClass();
    $('#directDebitPaymentDetailTable .paymentDetailsPaidByBIC td:nth-child(2)').removeClass();
    $('#directDebitPaymentDetailTable').addClass('hidden');
    $('#directDebitPaymentDetailTable .paymentDetailReversalReason').addClass('hidden');
    $('#directDebitPaymentDetailTable .paymentDetailReversalDate').addClass('hidden');


    //set all the fields
    $('#directDebitTable .created td:nth-child(2)').text(data.created_at);
    $('#directDebitTable .paymentDetailsTransactionId td:nth-child(2)').text(data.payment_details.transaction_id);
    $('#directDebitTable .paymentDetailsPurchase_id td:nth-child(2)').text(data.payment_details.purchase_id);
    $('#directDebitTable .currency td:nth-child(2)').text(data.currency);
    $('#directDebitTable .amount td:nth-child(2)').text(data.amount);

    $('#directDebitTable .paymentMethod td:nth-child(2)').text(data.payment_method);
    $('#directDebitTable .paymentDetailsDescription td:nth-child(2)').text(data.payment_details.description);


    $('#directDebitTable .status td:nth-child(2) div').text(data.status);
    $('#directDebitTable .test td:nth-child(2) div').text(((data.test == false) ? 'Live' : 'Test'));
    $('#directDebitTable .status td:nth-child(2) div').removeClass();
    $('#directDebitTable .status td:nth-child(2) div').addClass('value-field status ' + data.status.toLowerCase());
    $('#directDebitTable .test td:nth-child(2) div').removeClass();
    $('#directDebitTable .test td:nth-child(2) div').addClass('value-field status ' + ((data.test == false) ? 'success' : 'test'));

    $('#accountInformationHeader').removeClass('hidden');
    $('#directDebitPaymentDetailTable').removeClass('hidden');
    $('#directDebitPaymentDetailTable .paymentDetailsName td:nth-child(2)').text(data.payment_details.name);
    $('#directDebitPaymentDetailTable .paymentDetailsBankAccountNUmber td:nth-child(2)').text(data.payment_details.bank_account_number);

    if (data.payment_details.reverse_reason_code && data.payment_details.reverse_reason_description) {
        $('#directDebitPaymentDetailTable .paymentDetailReversalReason').removeClass('hidden');
        $('#directDebitPaymentDetailTable .paymentDetailReversalReason td:nth-child(2)').text(
            data.payment_details.reverse_reason_description + " (" + data.payment_details.reverse_reason_code + ")"
        );
    }

    if (data.payment_details.reversed_on) {
        $('#directDebitPaymentDetailTable .paymentDetailReversalDate').removeClass('hidden');
        $('#directDebitPaymentDetailTable .paymentDetailReversalDate td:nth-child(2)').text(data.payment_details.reversed_on);
    }
}

function showAliPay(data) {
    $('#AliPayDetails').removeClass('hidden');

    $('#aliPayTable .status td:nth-child(2) span').removeClass();
    $('#aliPayTable .test td:nth-child(2) span').removeClass();
    $('#aliPayPaymentDetailTable').addClass('hidden');
    $('#aliPayPaymentDetailTable .paymentDetailReversalReason').addClass('hidden');
    $('#aliPayPaymentDetailTable .paymentDetailReversalDate').addClass('hidden');


    //set all the fields
    $('#aliPayTable .created td:nth-child(2)').text(data.created_at);
    $('#aliPayTable .paymentDetailsTransactionId td:nth-child(2)').text(data.payment_details.transaction_id);
    $('#aliPayTable .paymentDetailsPurchase_id td:nth-child(2)').text(data.payment_details.purchase_id);
    $('#aliPayTable .currency td:nth-child(2)').text(data.currency);
    $('#aliPayTable .amount td:nth-child(2)').text(data.amount);

    $('#aliPayTable .paymentMethod td:nth-child(2)').text(data.payment_method);
    $('#aliPayTable .paymentDetailsDescription td:nth-child(2)').text(data.payment_details.description);


    $('#aliPayTable .status td:nth-child(2) div').text(data.status);
    $('#aliPayTable .test td:nth-child(2) div').text(((data.test == false) ? 'Live' : 'Test'));
    $('#aliPayTable .status td:nth-child(2) div').removeClass();
    $('#aliPayTable .status td:nth-child(2) div').addClass('value-field status ' + data.status.toLowerCase());
    $('#aliPayTable .test td:nth-child(2) div').removeClass();
    $('#aliPayTable .test td:nth-child(2) div').addClass('value-field status ' + ((data.test == false) ? 'success' : 'test'));

    $('#accountInformationHeader').removeClass('hidden');
    $('#aliPayPaymentDetailTable').removeClass('hidden');
    $('#aliPayPaymentDetailTable .paymentDetailsName td:nth-child(2)').text(data.payment_details.name);
    $('#aliPayPaymentDetailTable .paymentDetailsBankAccountNUmber td:nth-child(2)').text(data.payment_details.bank_account_number);

    if (data.payment_details.buyer_email && data.payment_details.buyer_id) {
        $('#aliPayPaymentDetailTable .paymentDetailBuyerEmail').removeClass('hidden');
        $('#aliPayPaymentDetailTable .paymentDetailBuyerEmail td:nth-child(2)').text(data.payment_details.buyer_email);
        $('#aliPayPaymentDetailTable .paymentDetailBuyerId').removeClass('hidden');
        $('#aliPayPaymentDetailTable .paymentDetailBuyerId td:nth-child(2)').text(data.payment_details.buyer_id);
    }

    if (data.payment_details.trade_no) {
        $('#aliPayTable .paymentDetailsTradeNo').removeClass('hidden');
        $('#aliPayTable .paymentDetailsTradeNo td:nth-child(2)').text(data.payment_details.trade_no);
    }
}


function showBancontact(data) {
    $('#BancontactPayDetails').removeClass('hidden');

    //set all the fields
    $('#bancontactTable .created td:nth-child(2)').text(data.created_at);
    $('#bancontactTable .paymentMethod td:nth-child(2)').text(data.payment_method);
    $('#bancontactTable .paymentDetailsDescription td:nth-child(2)').text(data.payment_details.description);
    $('#bancontactTable .paymentDetailsIssuer td:nth-child(2)').text(data.payment_details.issuer);
    $('#bancontactTable .paymentDetailsMaskedPan td:nth-child(2)').text(data.payment_details.masked_pan);
    $('#bancontactTable .paymentDetailsPurchaseID td:nth-child(2)').text(data.payment_details.purchase_id);
    $('#bancontactTable .currency td:nth-child(2)').text(data.currency);
    $('#bancontactTable .amount td:nth-child(2)').text(data.amount);
    if (data.status.toLowerCase() == 'failure') {
        $('#bancontactTable .paymentDetailsReasonForFailure').removeClass('hidden');
        $('#bancontactTable .paymentDetailsAcquirerResponse').removeClass('hidden');
        $('#bancontactTable .paymentDetailsReasonForFailure td:nth-child(2)').text(data.payment_details.reason_for_failure);
        $('#bancontactTable .paymentDetailsAcquirerResponse td:nth-child(2)').text(data.payment_details.acquirer_response_code);
    } else {
        $('#bancontactTable .paymentDetailsReasonForFailure').addClass('hidden');
        $('#bancontactTable .paymentDetailsAcquirerResponse').addClass('hidden');
    }
    $('#bancontactTable .paymentDetailsRisicoScore td:nth-child(2)').text(formatScore(data.payment_details.risico_score));
    removeScoreColors($('#bancontactTable .paymentDetailsScoreColor td:nth-child(2) div'));
    if (typeof data.payment_details.score_color !== 'undefined') {
        var scoreColor = data.payment_details.score_color.toLowerCase();
        scoreColor = scoreColor.charAt(0).toUpperCase() + scoreColor.slice(1);
        $('#bancontactTable .paymentDetailsScoreColor td:nth-child(2) div').text(scoreColor);
        switch (scoreColor) {
            case 'Green' :
                $('#bancontactTable .paymentDetailsScoreColor td:nth-child(2) div').addClass('success');
                break;
            case 'Orange' :
                $('#bancontactTable .paymentDetailsScoreColor td:nth-child(2) div').addClass('expired');
                break;
            case 'Black' :
                $('#bancontactTable .paymentDetailsScoreColor td:nth-child(2) div').addClass('risccolor_black');
                break;
            case 'Red' :
                $('#bancontactTable .paymentDetailsScoreColor td:nth-child(2) div').addClass('refundfailed');
                break;
            case 'White':
                $('#creditcardTable .paymentDetailsScoreColor td:nth-child(2) div').addClass('risccolor_white');
                break;
            default:
        }
    }
    $('#bancontactTable .paymentDetailsCustomerIP td:nth-child(2)').text(data.payment_details.customer_ip);
    $('#bancontactTable .paymentDetailsCardCountry td:nth-child(2)').text(data.payment_details.card_country);
    $('#bancontactTable .paymentDetailsIPCountry td:nth-child(2)').text(data.payment_details.ip_country);


    $('#bancontactTable .status td:nth-child(2) div').text(data.status);
    $('#bancontactTable .test td:nth-child(2) div').text(((data.test == false) ? 'Live' : 'Test'));
    $('#bancontactTable .status td:nth-child(2) div').removeClass();
    $('#bancontactTable .status td:nth-child(2) div').addClass('value-field status ' + data.status.toLowerCase());
    $('#bancontactTable .test td:nth-child(2) div').removeClass();
    $('#bancontactTable .test td:nth-child(2) div').addClass('value-field status ' + ((data.test == false) ? 'success' : 'test'));
}

function removeScoreColors(element) {
    element.removeClass('risccolor_black');
    element.removeClass('success');
    element.removeClass('expired');
    element.removeClass('refundfailed');
    element.removeClass('risccolor_white');
}

function formatScore(score) {
    if (typeof score !== 'undefined' && score !== '') {
        if ((n = score.indexOf('/')) != -1) {
            var scoreValues = score.split('/');
            if (scoreValues.length == 2) {
                var scoreValue = Number(scoreValues[0]).toFixed(1);
                var thresHold = Number(scoreValues[1]).toFixed(1);
                return scoreValue + '/' + thresHold;
            }
        }
    }
    return score;
}

function showCreditcard(data) {
    $('#CreditcardPayDetails').removeClass('hidden');

    //set all the fields
    $('#creditcardTable .created td:nth-child(2)').text(data.created_at);
    $('#creditcardTable .paymentMethod td:nth-child(2)').text(data.payment_method);
    $('#creditcardTable .paymentDetailsDescription td:nth-child(2)').text(data.payment_details.description);
    $('#creditcardTable .paymentDetailsIssuer td:nth-child(2)').text(data.payment_details.issuer);
    $('#creditcardTable .paymentDetailsMaskedPan td:nth-child(2)').text(data.payment_details.masked_pan);
    $('#creditcardTable .paymentDetailsPurchaseID td:nth-child(2)').text(data.payment_details.purchase_id);
    $('#creditcardTable .currency td:nth-child(2)').text(data.currency);
    $('#creditcardTable .amount td:nth-child(2)').text(data.amount);
    if (data.status.toLowerCase() == 'failure') {
        $('#creditcardTable .paymentDetailsReasonForFailure').removeClass('hidden');
        $('#creditcardTable .paymentDetailsAcquirerResponse').removeClass('hidden');
        $('#creditcardTable .paymentDetailsReasonForFailure td:nth-child(2)').text(data.payment_details.reason_for_failure);
        $('#creditcardTable .paymentDetailsAcquirerResponse td:nth-child(2)').text(data.payment_details.acquirer_response_code);
    } else {
        $('#creditcardTable .paymentDetailsReasonForFailure').addClass('hidden');
        $('#creditcardTable .paymentDetailsAcquirerResponse').addClass('hidden');
    }
    $('#creditcardTable .paymentDetailsRisicoScore td:nth-child(2)').text(formatScore(data.payment_details.risico_score));
    removeScoreColors($('#creditcardTable .paymentDetailsScoreColor td:nth-child(2) div'));
    if (typeof data.payment_details.score_color !== 'undefined') {
        var scoreColor = data.payment_details.score_color.toLowerCase();
        scoreColor = scoreColor.charAt(0).toUpperCase() + scoreColor.slice(1);
        $('#creditcardTable .paymentDetailsScoreColor td:nth-child(2) div').text(scoreColor);
        switch (scoreColor) {
            case 'Green' :
                $('#creditcardTable .paymentDetailsScoreColor td:nth-child(2) div').addClass('success');
                break;
            case 'Orange' :
                $('#creditcardTable .paymentDetailsScoreColor td:nth-child(2) div').addClass('expired');
                break;
            case 'Black' :
                $('#creditcardTable .paymentDetailsScoreColor td:nth-child(2) div').addClass('risccolor_black');
                break;
            case 'Red' :
                $('#creditcardTable .paymentDetailsScoreColor td:nth-child(2) div').addClass('refundfailed');
                break;
            case 'White':
                $('#creditcardTable .paymentDetailsScoreColor td:nth-child(2) div').addClass('risccolor_white');
                break;
            default:
        }
    }
    $('#creditcardTable .paymentDetailsCustomerIP td:nth-child(2)').text(data.payment_details.customer_ip);
    $('#creditcardTable .paymentDetailsCardCountry td:nth-child(2)').text(data.payment_details.card_country);
    $('#creditcardTable .paymentDetailsIPCountry td:nth-child(2)').text(data.payment_details.ip_country);

    $('#creditcardTable .status td:nth-child(2) div').text(data.status);
    $('#creditcardTable .test td:nth-child(2) div').text(((data.test == false) ? 'Live' : 'Test'));
    $('#creditcardTable .status td:nth-child(2) div').removeClass();
    $('#creditcardTable .status td:nth-child(2) div').addClass('value-field status ' + data.status.toLowerCase());
    $('#creditcardTable .test td:nth-child(2) div').removeClass();
    $('#creditcardTable .test td:nth-child(2) div').addClass('value-field status ' + ((data.test == false) ? 'success' : 'test'));
    if (data.recurring) {
        $('#creditcardTable .recurringId').removeClass('hidden');
        $('#creditcardTable .recurringId td:nth-child(2)').text(data.recurring_id);
    } else {
        $('#creditcardTable .recurringId').addClass('hidden');
    }

}


function showAfterpay(data) {
    $('#allAfterPay').removeClass('hidden');
    $('#AfterPayPayDetails').removeClass('hidden');
    $('table .addedDynamically').remove();

    $('#afterpayTable .created td:nth-child(2)').text(data.created_at);
    $('#afterpayTable .ipaddress td:nth-child(2)').text(data.payment_details.ip_address);
    $('#afterpayTable .portfolio-id td:nth-child(2)').text(data.payment_details.portfolio_id);
    $('#afterpayTable .payment-method td:nth-child(2)').text(data.payment_method);
    $('#afterpayTable .currency td:nth-child(2)').text(data.currency);
    $('#afterpayTable .amount td:nth-child(2)').text(data.amount);
    $('#afterpayTable .paymentDetailsReasonForFailure td:nth-child(2)').text(data.payment_details.reason_for_failure);

    $('#afterpayTable .status td:nth-child(2) div').text(data.status);
    $('#afterpayTable .test td:nth-child(2) div').text(((data.test == false) ? 'Live' : 'Test'));
    $('#afterpayTable .status td:nth-child(2) div').removeClass();
    $('#afterpayTable .status td:nth-child(2) div').addClass('value-field status ' + data.status.toLowerCase());
    $('#afterpayTable .test td:nth-child(2) div').removeClass();
    $('#afterpayTable .test td:nth-child(2) div').addClass('value-field status ' + ((data.test == false) ? 'success' : 'test'));

    $('#afterpayIBANTable .iban td:nth-child(2)').text(data.payment_details.bank_account_number);

    $('#afterpay-order-info .order-id td:nth-child(2)').text(data.payment_details.order_number);
    $('#afterpay-order-info .invoice-id td:nth-child(2)').text(data.payment_details.invoice_number);


    $('#afterpay-bill-info .bill-city td:nth-child(2)').text(data.payment_details.bill_to_address.city);
    $('#afterpay-bill-info .bill-street td:nth-child(2)').text(data.payment_details.bill_to_address.street_name);
    $('#afterpay-bill-info .bill-housenumber td:nth-child(2)').text(data.payment_details.bill_to_address.house_number + (data.payment_details.bill_to_address.house_number_addition.length != 0 ? ' ' + data.payment_details.bill_to_address.house_number_addition : ''));
    $('#afterpay-bill-info .bill-country td:nth-child(2)').text(data.payment_details.bill_to_address.iso_country_code);
    $('#afterpay-bill-info .bill-postal td:nth-child(2)').text(data.payment_details.bill_to_address.postal_code);
    $('#afterpay-bill-info .bill-lastname td:nth-child(2)').text(data.payment_details.bill_to_address.reference_person.last_name);
    $('#afterpay-bill-info .bill-initials td:nth-child(2)').text(data.payment_details.bill_to_address.reference_person.initials);
    $('#afterpay-bill-info .bill-email td:nth-child(2)').text(data.payment_details.bill_to_address.reference_person.email_address);
    $('#afterpay-bill-info .bill-phone td:nth-child(2)').text(data.payment_details.bill_to_address.reference_person.phone_number_1);
    $('#afterpay-bill-info .bill-gender td:nth-child(2)').text(data.payment_details.bill_to_address.reference_person.gender);
    $('#afterpay-bill-info .bill-language td:nth-child(2)').text(data.payment_details.bill_to_address.reference_person.iso_language);

    $('#afterpay-shipment-info .shipment-city td:nth-child(2)').text(data.payment_details.ship_to_address.city);
    $('#afterpay-shipment-info .shipment-street td:nth-child(2)').text(data.payment_details.ship_to_address.street_name);
    $('#afterpay-shipment-info .shipment-housenumber td:nth-child(2)').text(data.payment_details.ship_to_address.house_number + (data.payment_details.ship_to_address.house_number_addition.length != 0 ? ' ' + data.payment_details.ship_to_address.house_number_addition : ''));
    $('#afterpay-shipment-info .shipment-country td:nth-child(2)').text(data.payment_details.ship_to_address.iso_country_code);
    $('#afterpay-shipment-info .shipment-postal td:nth-child(2)').text(data.payment_details.ship_to_address.postal_code);
    $('#afterpay-shipment-info .shipment-lastname td:nth-child(2)').text(data.payment_details.ship_to_address.reference_person.last_name);
    $('#afterpay-shipment-info .shipment-initials td:nth-child(2)').text(data.payment_details.ship_to_address.reference_person.initials);
    $('#afterpay-shipment-info .shipment-email td:nth-child(2)').text(data.payment_details.ship_to_address.reference_person.email_address);
    $('#afterpay-shipment-info .shipment-phone td:nth-child(2)').text(data.payment_details.ship_to_address.reference_person.phone_number_1);
    $('#afterpay-shipment-info .shipment-gender td:nth-child(2)').text(data.payment_details.ship_to_address.reference_person.gender);
    $('#afterpay-shipment-info .shipment-language td:nth-child(2)').text(data.payment_details.ship_to_address.reference_person.iso_language);

    $.each(data.payment_details.order_line, function (i, line) {
        if ($("#afterpay-article-template-" + i).length == 0) {
            var id = "afterpay-article-template-" + i;
            $("#afterpay-article-template-0").clone().insertAfter("#afterpay-article-template-" + (i - 1)).addClass('addedDynamically').attr('id', id);
        }


    })

    $.each(data.payment_details.order_line, function (i, line) {
        var id = "#afterpay-article-template-" + i;
        $(id + ' #item-count').html(i + 1);
        $(id + ' .article-description td:nth-child(2)').html(line.article_description);
        $(id + ' .article-sku td:nth-child(2)').html(line.article_id);
        $(id + ' .article-quantity td:nth-child(2)').html(line.quantity);
        $(id + ' .article-price td:nth-child(2)').html(line.unit_price);
        $(id + ' .article-vat-category td:nth-child(2)').html(line.vat_category);
    })


}

/**
 * IMPORTANT KEEP THIS OUT OF THE DOCUMENT READY TO AVOID STRANGE BUGS
 */
$('#datetimepicker4').datetimepicker({
    locale: 'en',
    format: 'DD-MM-YYYY',
    icons: {
        time: "fa fa-clock-o",
        date: "fa fa-calendar",
        up: "fa fa-arrow-up",
        down: "fa fa-arrow-down",
        previous: "fa fa-arrow-left",
        next: "fa fa-arrow-right"
    },
    useCurrent: false
});


$('#datetimepicker5').datetimepicker({
    locale: 'en',
    format: 'DD-MM-YYYY',
    icons: {
        time: "fa fa-clock-o",
        date: "fa fa-calendar",
        up: "fa fa-arrow-up",
        down: "fa fa-arrow-down",
        previous: "fa fa-arrow-left",
        next: "fa fa-arrow-right"
    },
    useCurrent: false
});

$(document).on('change', '#amount_changeable', function () {
    if ($('#amount_changeable').is(':checked')) {
        $('.amount-changeable').removeClass('hidden');
    } else {
        $('.amount-changeable').addClass('hidden');
    }
});


$(document).on('click', '#request-qr-button', function () {
    var csrf_token = $("input[name=_token]").val();
    $.ajaxPrefilter(function (options, originalOptions, jqXHR) {
        if (options.type.toLowerCase() === "post") {
            options.data = options.data || "";
            options.data += options.data ? "&" : "";
            options.data += "_token=" + csrf_token;
        }
    });

    $.ajax({
        url: "qr/request",
        type: "post",
        dataType: 'json',
        data: {
            amount: $("#amount").val(),
            amount_changeable: $('#amount_changeable').is(':checked'),
            amount_min: $("#amount_min").val(),
            amount_max: $("#amount_max").val(),
            description: $("#description").val(),
            oneoff: $('#oneoff').is(':checked'),
            expire: $("#expire").val(),
            url_success: $("#url_success").val(),
            url_cancel: $("#url_cancel").val(),
            url_failed: $("#url_failed").val(),
            url_expired: $("#url_expired").val(),
            purchase_id: $("#purchase_id").val(),
            size: $("#size").val()
        },
        success: function (result) {
            $('#error-message').addClass('hidden');
            $('#success-message').removeClass('hidden');
            var json = $.parseJSON(JSON.stringify(result));
            $("#ideal-qr-id").text(json.qr_id);
            $("#ideal-qr-url-location").text(json.qr_code_url);
            $("#ideal-qr-url-location").attr("href", json.qr_code_url);
            $("#ideal-qr-img").attr("src", json.qr_code_url);
        },
        error: function (result) {
            $('#error-message').removeClass('hidden');
            $('#success-message').addClass('hidden');
            var json = $.parseJSON(JSON.stringify(result.responseJSON));
            $("#error-message .panel-body ul").empty();
            $.each(json.errors, function (key, value) {
                $("#error-message .panel-body ul").append('<li>' + value.message + '</li>');
            });
        }
    });
});

let timerInterval = null;

function onTimesUp() {
    clearInterval(timerInterval);
}

function startTimer(duration = 50) {
    let timePassed = 0;
    let timeLeft = duration;

    timerInterval = setInterval(() => {
        timePassed = timePassed += 1;
        timeLeft = duration - timePassed;
        $("#timer").html("You have " + timeLeft + " seconds left to enter the sms code");

        if (timeLeft === 0) {
            onTimesUp();
            showResendBtn();
            timePassed = 0
        }
    }, 1000);
}

function showResendBtn() {
    $("#timer").html("The SMS code is expired, click the resend button to receive another code.");
    $("#resend").css("display", "block");
    $("#verify").css("display", "none");
    $("#smsCode").removeAttr('value');
}

function showVerifyBtn() {
    $("#timer").empty();
    $("#resend").css("display", "none");
    $("#verify").css("display", "block");
    onTimesUp();
}

$("#resend").css("display", "none");
$("#error-header").css("display", "none");
startTimer();

function showErrorMsg(errorElement, errorElementContainer, msg) {
    $(`#${errorElement}`).html(msg);

    $(`#${errorElementContainer}`)
        .fadeIn("slow")
        .removeClass('hide')
        .addClass('show')

    setTimeout(() => {
        $(`#${errorElementContainer}`)
            .fadeOut("slow")
            .removeClass('show')
            .addClass('hide')
    }, 5000)
}

var phoneNumber = $('#phoneNumber')
var verifyPhoneNumberBtn = $('#verifyPhoneNumberBtn')

var inputTimer;
var doneTypingInterval = 1500; // 1,5s

phoneNumber.on('keyup', function() {
    clearTimeout(inputTimer)

    if (!this.value || phoneNumber.val().length < 6) {
        verifyPhoneNumberBtn.prop("disabled", true)
    }

    if (!$.isNumeric(phoneNumber.val())) {
        showErrorMsg('error-msg', 'error-msg-container', 'Please enter a valid phone number')
    }

    inputTimer = setTimeout(verifyPhoneNumber, doneTypingInterval)
})

phoneNumber.on('keydown', function(e) {
    clearTimeout(inputTimer);

    var ASCIICode = (e.which) ? e.which : e.keyCode

    if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) {
        return false
    }

    return true
})

$('#smsCode').on('keyup', () => {
    $('#verify-btn').prop("disabled", false)
})

function verifyPhoneNumber() {
    if (phoneNumber.val().length >= 6) {

        verifyPhoneNumberBtn.html('Verifying...')

        var csrf_token = $("input[name=_token]").val();
        $.ajaxPrefilter(function (options, originalOptions, jqXHR) {
            if (options.type.toLowerCase() === "post") {
                options.data = options.data || "";
                options.data += options.data ? "&" : "";
                options.data += "_token=" + csrf_token;
            }
        });

        $.ajax({
            url: `phone-number-verify/${Number(phoneNumber.val())}`,
            type: "GET",
            dataType: 'json',
            success: function (response) {
                verifyPhoneNumberBtn.html('Send sms code')

                verifyPhoneNumberBtn.prop("disabled", !response.valid)

                if (!response.valid) {
                    verifyPhoneNumberBtn.html('Invalid phone number')
                    showErrorMsg('error-msg', 'error-msg-container', response.errorMsg)
                }

                if (response.valid) {
                    verifyPhoneNumberBtn.on('click', () => {
                        showVerifyCodeForm(response.phoneNumber)
                        verifyPhoneNumberBtn.onclick = null
                    })
                }
            },
            error: function (error) {
                console.log(error)

                showErrorMsg('error-msg', 'error-msg-container', JSON.parse(error.responseText))

                console.clear()
            }
        })

    }
}

function showVerifyCodeForm(phoneNumber) {
    var csrf_token = $("input[name=_token]").val();
    $.ajaxPrefilter(function (options, originalOptions, jqXHR) {
        if (options.type.toLowerCase() === "post") {
            options.data = options.data || "";
            options.data += options.data ? "&" : "";
            options.data += "_token=" + csrf_token;
        }
    });

    $.ajax({
        url: 'send-code',
        method: 'POST',
        data: {
            phone_number: phoneNumber
        },
        success: function () {
            document.location.href = '/verify-code'
        },
        error: function () {
            showErrorMsg('error-msg', 'error-msg-container', 'SMS could not be send. Please try again later')
        }
    })
}

function verifyCode() {

    $('#verify-btn').prop("disabled", true)

    var csrf_token = $("input[name=_token]").val();
    $.ajaxPrefilter(function (options, originalOptions, jqXHR) {
        if (options.type.toLowerCase() === "post") {
            options.data = options.data || "";
            options.data += options.data ? "&" : "";
            options.data += "_token=" + csrf_token;
        }
    });

    $.ajax({
        url: "verify-code",
        type: "post",
        dataType: 'json',
        data: {
            smsCode: $("#smsCode").val()
        },
        success: function (response) {
            if (response.status) {
                document.location.href = '/transactions';
            } else {
                console.clear();

                $("#error-header").fadeIn("slow")

                setTimeout(() => {
                    $("#error-header").fadeOut("slow")
                }, 7000)
            }
        },
        error: function (error) {
            $("#sms-code-error").text(JSON.parse(error.responseText).smsCode).fadeIn("slow")

            console.clear()

            setTimeout(() => {
                $("#sms-code-error").fadeOut("slow")
            }, 5000)

            $("#verify-btn").css("margin-top", "15px")

        }
    })
}

$("#smsCode").on("keyup", (e) => {
    if(e.keyCode ===13) {
        verifyCode();
    }
})

$('#verify-container').ready(() => {
    verifyPhoneNumberBtn.prop("disabled", true)
})
